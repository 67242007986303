@keyframes buttons {
    0% {
        transform: rotate(-15deg);
    }

    50% {
        transform: rotate(5deg);
    }

    100% {
        transform: rotate(-15deg);
    }
}

.RoundedButton {
    border-radius: 100px;
    border-color: transparent;
    width: 35px;
    height: 35px;
    background-color: rgb(238, 238, 238);
    color: rgb(0, 0, 0);
}

.RoundedButton:not([disabled]):hover {
    /* box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.295); */
    transform: scale(1.2);
    /* background-color: rgb(189, 189, 189);
    color: rgb(255, 255, 255); */
    cursor: pointer;
}

.RoundedButton:not([disabled]):hover .icono {
    animation: buttons 0.5s ease-in-out infinite;
}

/* Establece el estilo para los botones deshabilitados */
.RoundedButton[disabled] {
    /* Agrega tus estilos aquí */
    background-color: #ddd;
    color: #aaa;
    cursor: not-allowed;
}

