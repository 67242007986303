@keyframes scaleIt {
    0% {
        transform: scale(0.9);
    }
  
    50% {
        transform: scale(1);
    }
  
    100% {
        transform: scale(0.9);
    }
  }

.screen {
    display: flex;
    justify-content: center; 
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 3rem;
    padding-left: 3rem;
    width: 80%;
    min-width: 300px;
    color: slategrey;
}

.screen img{
    width: 4rem;
    height: 4rem;
    
    border-radius: 100%;
}

.screen img:hover{
    animation: scaleIt .5s infinite;
    cursor: pointer;
}

.screen span{
    display: flex;
    justify-content: center; 
    align-items: center;
    right: 0;
    left: 0;
}
